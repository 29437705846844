<template>
  <collapsable-card :visible.sync="visible">
    <template v-slot:append-left>
      <div class="mr-3">
        <b-form-checkbox
          v-model="checked"
          :disabled="
            !$can(adminAccess.EDIT_ADMIN_ACCESS, adminAccess.moduleName)
          "
          :indeterminate="indeterminate"
        />
      </div>
    </template>
    <template v-slot:header>
      <div class="title">{{ access }}</div>
    </template>
    <div class="pt-3 d-flex flex-row">
      <div class="v1 mx-2" />
      <div class="px-4">
        <b-form-checkbox
          v-for="(_, key) in actions"
          :key="key"
          v-model="selected"
          :disabled="
            !$can(adminAccess.EDIT_ADMIN_ACCESS, adminAccess.moduleName)
          "
          class="py-1 check-permission"
          :value="key"
        >
          {{ key }}
        </b-form-checkbox>
      </div>
    </div>
  </collapsable-card>
</template>

<script>
import AdminAccess from "@/constants/access/admins";
import CollapsableCard from "@zubut/common/src/components/CollapsableCard";

export default {
  name: "AdminRoleAccess",

  components: {
    CollapsableCard
  },

  props: {
    access: {
      type: String,
      required: true
    },
    actions: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      adminAccess: AdminAccess,
      selected: [],
      indeterminate: false,
      checked: true,
      visible: false,
      block: false
    };
  },

  computed: {
    totalActions() {
      return Object.keys(this.actions).length;
    }
  },

  watch: {
    actions: {
      handler() {
        this.formatSelectedValues().then(() => (this.block = false));
      },
      deep: true
    },

    checked(isChecked) {
      if (isChecked) {
        this.selectAllValues().then(() => {
          this.block = false;
          this.buildAndEmit(this.selected);
        });
      } else {
        this.cleanAllValues();
      }
    },

    selected: {
      handler(selected) {
        if (selected.length == this.totalActions) {
          this.indeterminate = false;
          this.checked = true;
        } else if (selected.length != 0) {
          this.indeterminate = true;
        } else {
          this.indeterminate = false;
          this.checked = false;
        }
        this.buildAndEmit(selected);
      },
      deep: true,
      immediate: false
    }
  },

  beforeMount() {
    this.formatSelectedValues().then(() => {
      this.block = false;
    });
  },

  methods: {
    async formatSelectedValues() {
      this.cleanAllValues();
      this.block = true;
      for (const key in this.actions) {
        if (this.actions[key]) {
          this.selected.push(key);
        }
      }
    },

    async selectAllValues() {
      this.cleanAllValues();
      this.block = true;
      for (const key in this.actions) {
        this.selected.push(key);
      }
    },

    cleanAllValues() {
      this.selected = [];
    },

    buildAndEmit(selected) {
      if (!this.block) {
        const objSelected = this.toObject(selected);
        const obj = { [this.access]: objSelected };
        this.$emit("update-access", obj);
      }
    },

    toObject(arr) {
      return arr.reduce((a, b) => ((a[b] = true), a), {});
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  color: $nero;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}

.check-permission {
  color: $nero;
  font-size: 12px;
}

.v1 {
  border-left: 2px solid $gainsboro;
}

.title-class {
  color: $nero;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
</style>
