<template>
  <div class="">
    <p class="title">Datos Personales</p>
    <div class="mb-3 profile-image-wrapper">
      <slot name="profile-image">
        <z-profile-image
          :type="isDriver ? 'driver' : 'user'"
          :business-line="businessLine"
          size="lg"
          :image="profileImg"
        />
      </slot>
    </div>
    <div class="edit-container">
      <div v-if="hasName">
        <z-input
          id="name"
          v-model="draftName"
          :label="headerNameInput"
          :state="nameState"
          class="mt-4"
          :disabled="!editMode"
        />
      </div>
      <div v-if="hasContact">
        <z-input
          id="contact"
          v-model="draftContact"
          label="Contacto"
          :state="contactState"
          class="mt-4"
          :disabled="!editMode"
        />
      </div>
      <div v-if="hasEmail">
        <z-input
          id="email"
          v-model="draftEmail"
          type="email"
          label="Correo electrónico"
          :state="emailState"
          class="mt-4"
          :disabled="!editMode"
        />
      </div>
      <div v-if="hasPhone">
        <z-input
          id="phone"
          v-model="draftPhone"
          type="tel"
          label="Teléfono"
          :state="phoneState"
          class="mt-4"
          :disabled="!editMode"
        />
      </div>
    </div>
    <div v-if="editMode" class="d-flex flex-column flex-sm-row mt-4">
      <z-button
        variant="secondary"
        class="z-action w-auto mr-sm-3"
        @click="clearForm(false)"
      >
        Cancelar
      </z-button>
      <z-button
        :disabled="isLoading || !validForm"
        :loading="isLoading"
        class="z-action w-auto mt-3 mt-sm-0"
        @click="editInformation"
      >
        Guardar cambios
      </z-button>
    </div>
    <div v-else class="d-flex flex-column flex-sm-row mt-4">
      <z-button
        v-if="
          $can(
            accountAccess.EDIT_ACCOUNT_BASIC_INFO,
            accountAccess.moduleName
          ) ||
            $can(driverAccess.EDIT_DRIVER_BASIC_INFO, driverAccess.moduleName)
        "
        class="z-action w-auto"
        @click="clearForm(true)"
      >
        Editar
      </z-button>
    </div>
    <quick-message
      class="mt-3"
      with-icon
      :show.sync="hasErrors"
      :message="message"
      type="error"
    />
  </div>
</template>

<script>
import AccountAccess from "@/constants/access/accounts";
import DriverAccess from "@/constants/access/drivers";
import { validateEmail, validateName, validatePhone } from "@/utils/strings";
import BusinessLine from "@/constants/clients/businessLine";
import Type from "@zubut/common/src/constants/clients/type";
import Roles from "@/constants/roles/type";
import ZProfileImage from "@/app/components/ZProfileImage";
import { formatSendPhone, formatPhone } from "@zubut/common/src/utils/strings";

const DRIVER = "drivers";
const CLIENT = "clients";
const ADMIN = "admin";

export default {
  name: "CardDetailInfo",

  components: {
    ZProfileImage
  },

  props: {
    component: {
      type: String,
      required: true,
      validator: value => {
        return [CLIENT, ADMIN, DRIVER].indexOf(value) !== -1;
      }
    },
    name: {
      type: String,
      default: null
    },
    contact: {
      type: String,
      default: null
    },
    type: {
      type: Number,
      default: 0
    },
    phone: {
      type: String,
      default: null
    },
    businessLine: {
      type: Number,
      default: null
    },
    roleType: {
      type: Number,
      default: null
    },
    email: {
      type: String,
      default: null
    },
    driverAlpha: {
      type: Boolean,
      default: false
    },
    profileImg: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      accountAccess: AccountAccess,
      driverAccess: DriverAccess,
      isLoading: false,
      editMode: false,
      wasEditedUser: false,
      hasErrors: false,
      message: "",
      draftName: "",
      draftContact: "",
      draftEmail: "",
      draftPhone: "",
      vueTel: ""
    };
  },

  computed: {
    isDriver() {
      return this.component === DRIVER;
    },

    isClient() {
      return this.component === CLIENT;
    },

    isAdmin() {
      return this.component === ADMIN;
    },

    businessType() {
      if (this.businessLine != null) {
        return BusinessLine.get[this.businessLine];
      } else {
        return "Linea de negocio";
      }
    },

    role() {
      if (this.roleType != null) {
        return Roles.get[this.roleType];
      } else {
        return "Rol";
      }
    },

    headerName() {
      if (this.isDriver)
        return `Mensajero ${this.driverAlpha ? "(de confianza)" : ""}`;
      if (this.isClient) {
        return `${Type.get[this.type]} (${this.businessType})`;
      }
      if (this.isAdmin) return `Administrador (${this.role})`;
      return "Nombre";
    },

    headerNameInput() {
      if (this.isDriver) return "Nombre completo";
      if (this.isClient) {
        return `Nombre de ${Type.get[this.type]} `;
      }
      if (this.isAdmin) return "Nombre completo";
      return "Nombre";
    },

    hasPhone() {
      return this.phone !== null;
    },

    hasEmail() {
      return this.email !== null;
    },

    hasName() {
      return this.name !== null;
    },

    hasContact() {
      return this.contact !== null;
    },

    emailState() {
      if (!this.editMode) {
        return null;
      }
      if (!this.draftEmail) {
        return true;
      }
      return this.draftEmail.length === 0
        ? null
        : validateEmail(this.draftEmail);
    },

    phoneState() {
      if (!this.editMode) {
        return null;
      }
      return this.draftPhone.length === 0
        ? null
        : validatePhone(this.draftPhone);
    },

    invalidPhoneFeedback() {
      if (this.phoneState) {
        return "";
      } else {
        return "Ingresa un formato de teléfono valido";
      }
    },

    nameState() {
      if (!this.editMode) {
        return null;
      }
      return this.draftName.length === 0 ? null : validateName(this.draftName);
    },

    contactState() {
      if (!this.editMode) {
        return null;
      }
      return this.draftContact == null || this.draftContact.length === 0
        ? null
        : validateName(this.draftContact);
    },

    validForm() {
      return (
        this.wasEditedUser &&
        this.emailState &&
        (this.phoneState == null || this.phoneState) &&
        this.nameState &&
        (!this.hasContact || this.contactState)
      );
    }
  },

  watch: {
    draftEmail(newVal) {
      if (newVal && newVal.trim() !== this.email) {
        this.wasEditedUser = true;
      } else {
        this.wasEditedUser = false;
      }
    },

    draftName(newVal) {
      if (newVal && newVal.trim() !== this.name) {
        this.wasEditedUser = true;
      } else {
        this.wasEditedUser = false;
      }
    },

    draftContact(newVal) {
      if (newVal && newVal.trim() !== this.contact) {
        this.wasEditedUser = true;
      } else {
        this.wasEditedUser = false;
      }
    },

    draftPhone(newVal) {
      if (newVal && newVal.trim() !== this.phone) {
        this.wasEditedUser = true;
      } else {
        this.wasEditedUser = false;
      }
    }
  },

  mounted() {
    this.draftName = this.name;
    this.draftContact = this.contact;
    this.draftEmail = this.email;
    this.draftPhone = this.hasPhone ? formatPhone(this.phone) : "";
  },

  methods: {
    clearForm(edit) {
      this.editMode = edit;
      this.draftName = this.name;
      this.draftContact = this.contact;
      this.draftEmail = this.email;
      this.draftPhone = this.hasPhone ? formatPhone(this.phone) : "";
      this.wasEditedUser = false;
    },

    editInformation() {
      this.isLoading = true;
      Promise.all([this.editUser()])
        .then(() => {
          this.clearForm();
        })
        .catch(err => {
          this.message = "Ocurrió un error al editar la información";
          this.hasErrors = true;
          setTimeout(() => {
            this.hasErrors = false;
          }, 5000);
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    editUser() {
      return new Promise((resolve, reject) => {
        const safePhoneLength = this.draftPhone?.length ?? 0;
        const data = {
          name: this.draftName,
          phone:
            safePhoneLength !== 0 ? formatSendPhone(this.draftPhone) : undefined
        };
        data.contact = this.draftContact ? this.draftContact : undefined;
        data.email = this.draftEmail ? this.draftEmail : undefined;
        const id = this.$route.params.id;
        this.$store
          .dispatch(`${this.component}/updateUser`, { id, data })
          .then(() => {
            this.$emit("update:email", this.draftEmail);
            this.$emit("update:name", data.name);
            this.$emit("update:contact", data.contact);
            if (safePhoneLength > 0) this.$emit("update:phone", data.phone);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-image-wrapper {
  display: inline-block;
  padding: 4px;
  border: 1px solid $zircon;
  border-radius: 8px;
}
.title {
  font-size: 14px;
  font-weight: 600;
}

.z-action {
  font-weight: 500;
  height: 40px;
  width: 100px;
}

.z-section {
  color: $black;

  p:first-child {
    font-size: 12px;
    margin: 0;
  }

  p:last-child {
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 20px;
  }
}

.edit-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 0 15px;
  ::v-deep .form-control.is-valid {
    background-image: none;
  }

  .m-label {
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 4px;
  }

  input {
    max-width: 460px;
    border: 1px solid $gainsboro;
  }

  ::v-deep .form-control::placeholder {
    font-size: 12px;
    color: $dim-gray;
  }
}

#phone {
  max-width: 460px;
}

.text {
  font-size: 12px;

  &.info {
    color: $gainsboro;
  }
}
@include media-breakpoint-down(xs) {
  .edit-container {
    grid-template-columns: auto;
  }
}
</style>
