

































































import Vue from "vue";
import Admin from "@/services/admins";

export default Vue.extend({
  name: "AdminDetailPassword",
  data() {
    return {
      enableEdit: false,
      currentPassword: "",
      newPassword: "",
      confirmPass: "",
      message: "",
      typeOfMessage: "error",
      isSuccess: false,
      hasError: false
    };
  },

  computed: {
    hasMessage: {
      get(): boolean {
        return this.hasError || this.isSuccess;
      },
      set(newVal: boolean): void {
        this.hasError = newVal;
        this.isSuccess = newVal;
      }
    },
    passwordState(): boolean | null {
      // Return null in success state for avoid boostrap success feedback
      return !this.enableEdit
        ? null
        : this.newPassword.length === 0
        ? false
        : this.newPassword.trim().length >= 6
        ? true
        : false;
    },
    confirmPasswordState(): boolean | null {
      // Return null in success state for avoid boostrap success feedback
      return !this.enableEdit
        ? null
        : this.confirmPass.length === 0
        ? false
        : this.confirmPass === this.newPassword && this.passwordState
        ? true
        : false;
    },
    validCurrentPassword(): boolean | null {
      return !this.enableEdit
        ? null
        : this.currentPassword.trim().length !== 0
        ? null
        : false;
    },
    validForm(): boolean | null {
      return (
        this.currentPassword.length > 0 &&
        this.newPassword.trim().length >= 6 &&
        this.newPassword === this.confirmPass &&
        this.newPassword !== this.currentPassword
      );
    }
  },

  methods: {
    handleEdit() {
      this.enableEdit = true;
    },

    handleCancel() {
      this.enableEdit = false;
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmPass = "";
    },

    updatePassword() {
      if (!this.validForm) {
        return;
      }
      const oldPassword = this.currentPassword;
      const newPassword = this.newPassword;
      Admin.changePassword({
        oldPassword,
        newPassword
      })
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .catch(err => {
          this.hasError = true;
          this.message = err.message;
          this.typeOfMessage = "error";
          this.$captureError(err);
        });
    }
  }
});
