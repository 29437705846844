<template>
  <div>
    <quick-message
      class="mb-3"
      with-icon
      :show.sync="hasMessage"
      :message="message"
      :type="hasError ? 'error' : 'success'"
    />
    <b-row>
      <b-col>
        <div class="mb-3">
          <span class="font-weight-bold">Rol de usuario</span>
          <div>{{ userRoleName }}</div>
        </div>
        <div v-if="$can(adminAccess.EDIT_ADMIN_ACCESS, adminAccess.moduleName)">
          <z-button :disabled="!hasChanges" class="mr-3" @click="editAccess">
            Guardar
          </z-button>
          <z-button
            :disabled="!hasChanges"
            variant="secondary"
            @click="restoreUserAccess"
          >
            Cancelar
          </z-button>
        </div>
      </b-col>
    </b-row>
    <div v-if="loadingRolesAccess || editingAccess">
      <loading-spinner />
    </div>
    <b-row v-else>
      <b-col
        v-for="(access, key) in defaultAccess"
        :key="`${key}-${accessKey}`"
        cols="12"
      >
        <admin-role-access
          v-if="key !== 'Cuentas'"
          class="mt-3"
          :access="key"
          :actions="access"
          @update-access="updateAccess"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AdminAccess from "@/constants/access/admins";
import UserRole from "@/constants/roles/type";
import AdminRoleAccess from "@/app/views/Admins/AdminRoleAccess";
import _cloneDeep from "lodash/cloneDeep";
import _assign from "lodash/assign";

export default {
  name: "AdminDetailAccess",

  components: {
    AdminRoleAccess
  },

  props: {
    role: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      adminAccess: AdminAccess,
      accessKey: 1,
      defaultAccess: null,
      userAccess: null,
      editedAccess: null,
      hasChanges: false,
      loadingRolesAccess: false,
      editingAccess: false,
      userRoleName: UserRole.get[this.role.type],
      hasError: false,
      isSuccess: false,
      message: ""
    };
  },

  computed: {
    hasMessage: {
      get() {
        return this.hasError || this.isSuccess;
      },
      set(newVal) {
        this.hasError = newVal;
        this.isSuccess = newVal;
      }
    }
  },

  watch: {
    role: {
      immediate: true,
      handler(newVal) {
        this.userAccess = _cloneDeep(newVal.access);
        this.editedAccess = _cloneDeep(newVal.access);
      }
    },

    editedAccess() {
      this.hasChanges = true;
    }
  },

  beforeMount() {
    this.getDefaultAccess(this.role.type);
  },

  methods: {
    getDefaultAccess(role) {
      this.loadingRolesAccess = true;

      this.$store
        .dispatch("admin/getDefaultRoleAccess", role)
        .then(res => {
          this.defaultAccess = res.access;
          this.compareAccess(this.defaultAccess, this.editedAccess);
        })
        .catch(err => {
          this.$captureError(err);
          console.error("getting rol", err);
        })
        .finally(() => {
          this.loadingRolesAccess = false;
        });
    },

    compareAccess(accessA, accessB) {
      Object.keys(accessA).forEach(key => {
        Object.keys(accessA[key]).forEach(subkey => {
          if (!accessB[key]) {
            accessA[key][subkey] = false;
          } else {
            accessA[key][subkey] = accessB[key][subkey];
          }
        });
      });
    },

    updateAccess(obj) {
      this.editedAccess = _assign({}, this.editedAccess, obj);
    },

    editAccess() {
      this.editingAccess = true;
      this.$store
        .dispatch("admin/editAccess", {
          id: this.$route.params.id,
          access: this.editedAccess || {}
        })
        .then(() => {
          this.isSuccess = true;
          this.message = "Permisos de usuario actualizados.";
          this.userAccess = _cloneDeep(this.editedAccess);
          this.compareAccess(this.defaultAccess, this.editedAccess);
          this.hasChanges = false;
        })
        .catch(err => {
          this.hasError = true;
          this.message = err.message;
          this.$captureError(err);
        })
        .finally(() => {
          this.editingAccess = false;
        });
    },

    restoreUserAccess() {
      this.editedAccess = _cloneDeep(this.userAccess);
      this.compareAccess(this.defaultAccess, this.editedAccess);
      this.accessKey++;

      this.$nextTick(() => {
        this.hasChanges = false;
      });
    }
  }
};
</script>
