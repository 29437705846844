<template>
  <div class="z-grid-page">
    <div class="card p-4 rounded-lg info">
      <card-detail-info
        component="admin"
        :role-type="admin.role.type"
        :name.sync="admin.name"
        :email.sync="admin.email"
      />
    </div>
    <div
      v-if="$can(adminAccess.BLOCK_ADMIN, adminAccess.moduleName) && !isTheSame"
      class="card p-4 rounded-lg account"
    >
      <card-detail-account component="admin" :status.sync="admin.status" />
    </div>
    <admin-detail-password v-if="isTheSame" class="card p-4 rounded-lg pass" />
    <div
      v-else-if="$can(adminAccess.RESET_PASSWORD, adminAccess.moduleName)"
      class="card p-4 rounded-lg pass"
    >
      <card-detail-password component="admin" />
    </div>
  </div>
</template>

<script>
import AdminAccess from "@/constants/access/admins";
import CardDetailInfo from "@/app/components/DetailProfile/CardDetailInfo";
import CardDetailAccount from "@/app/components/DetailProfile/CardDetailAccount";
import CardDetailPassword from "@/app/components/DetailProfile/CardDetailPassword";
import AdminDetailPassword from "./AdminDetailPassword";

export default {
  name: "AdminDetailProfile",
  components: {
    CardDetailAccount,
    CardDetailInfo,
    CardDetailPassword,
    AdminDetailPassword
  },
  props: {
    admin: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      adminAccess: AdminAccess
    };
  },
  computed: {
    isTheSame() {
      return this.$store.getters["auth/getSessionUserId"] === this.admin.id;
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  color: $black;
  border: 1px solid $gainsboro;
}

.z-grid-page {
  display: grid;
  grid-gap: 0.5em;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: 1fr;
  grid-auto-flow: dense;
  grid-auto-rows: 1fr;

  .info {
    grid-row: span 3;
  }

  .account {
    grid-row: span 1;
  }

  .rate {
    grid-row: span 1;
  }

  .pass {
    grid-row: span 1;
  }
}

@media only screen and (min-width: 768px) {
  .z-grid-page {
    grid-gap: 0;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: repeat(2, 1fr);

    .info {
      grid-row: span 5;
      grid-column: 1;
    }

    .account {
      grid-column: 2;
    }

    .rate {
      grid-column: 2;
    }

    .pass {
      grid-column: 2;
    }
  }
}
</style>
