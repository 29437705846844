<template>
  <div
    class="collapsable-card border p-4 rounded"
    :class="[customCardClass, variantClass]"
  >
    <div class="header d-flex justify-content-between align-items-center w-100">
      <div class="d-flex align-items-center w-100">
        <slot name="append-left"></slot>
        <slot name="header" class="bg-primary">
          <div class="title font-weight-bold">{{ title }}</div>
          <div class="subtitle ml-3">{{ subtitle }}</div>
        </slot>
      </div>
      <div>
        <div
          id="collapse-btn"
          variant="secondary"
          class="text-dark"
          :class="{ 'point-up': visible }"
          @click="toggle"
        >
          <font-awesome-icon icon="chevron-down" />
        </div>
      </div>
    </div>
    <b-collapse
      id="collapsable-section"
      :visible="visible"
      @input="$emit('update:visible', $event)"
    >
      <slot></slot>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "CollapsableCard",

  props: {
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: false
    },
    customCardClass: {
      type: Array,
      default: () => []
    },
    variant: {
      type: String,
      default: "default",
      validator: val => ["default", "secondary"].indexOf(val) > -1
    }
  },

  computed: {
    variantClass() {
      return `collapsable-card-${this.variant}`;
    }
  },

  methods: {
    toggle() {
      this.$emit("update:visible", !this.visible);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";

#collapse-btn {
  cursor: pointer;
  padding: 0.5em 1em;

  &:hover {
    background-color: $white-smoke;
  }

  &.point-up svg {
    transform: rotate(180deg);
  }

  svg {
    transition: transform 0.3s ease;
  }
}

.collapsable-card-secondary {
  background-color: $ghost-white;
}
</style>
