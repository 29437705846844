<template>
  <div>
    <b-card class="p-2">
      <go-back
        v-show="!isLoading && admin"
        :title="title"
        :subtitle="subtitle"
        @back-action="$router.go(-1)"
        ><template #end>
          <buttons-layout>
            <activity-button :id="id" type="admin" />
          </buttons-layout>
        </template>
      </go-back>
      <div>
        <div v-if="isLoading && !admin">
          <loading-spinner />
        </div>
        <div v-else class="py-4">
          <b-tabs content-class="mt-3 p-0">
            <b-tab
              title="Perfil"
              :active="!$route.query.tab || $route.query.tab === 'perfil'"
              @click="changeTab('perfil')"
            >
              <loading-spinner v-if="isLoading" />
              <admin-detail-profile v-else :admin="admin" />
            </b-tab>
            <b-tab lazy title="Permisos" @click="changeTab('permisos')">
              <admin-detail-access :role="role" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { formatISO } from "@zubut/common/src/utils/time";
import GoBack from "@zubut/common/src/components/GoBackTitle";
import Admin from "@/services/admins";
import notifyMixin from "@/mixins/notify";
import AdminDetailAccess from "./AdminDetailAccess";
import AdminDetailProfile from "./AdminDetailProfile";
import ActivityButton from "@/app/components/ActivityButton";
import Http from "@zubut/common/src/constants/http";
import ButtonsLayout from "@/app/layouts/ButtonsLayout.vue";

export default {
  name: "AdminDetail",

  components: {
    AdminDetailAccess,
    AdminDetailProfile,
    GoBack,
    ActivityButton,
    ButtonsLayout
  },

  mixins: [notifyMixin("notifications")],

  data() {
    return {
      admin: null,
      isLoading: true
    };
  },

  computed: {
    adminUser() {
      return this.$store.state.auth.user;
    },
    title() {
      return this.admin?.name ?? "Administrador";
    },
    subtitle() {
      const admin = this.admin;
      if (admin && admin.lastLogin) {
        return `Último inicio de sesión ${formatISO(admin.lastLogin)}`;
      }
      return "No existe última sesión";
    },
    role() {
      return this.admin?.role;
    },
    id() {
      return this.$route.params.id;
    }
  },

  beforeMount() {
    this.getAdmin();
  },

  created() {
    this.changeTab("perfil");
  },

  methods: {
    changeTab(tab) {
      this.$router
        .replace({ name: "adminDetail", query: { tab } })
        .catch(error => {
          this.$captureError(error);
        });
    },

    getAdmin() {
      this.isLoading = true;
      const adminId = this.$route.params.id;
      const filter = {
        include: ["role"]
      };
      Admin.findById({
        id: adminId,
        filter
      })
        .then(admin => {
          this.admin = { ...admin };
          this.isLoading = false;
        })
        .catch(err => {
          if (err.statusCode === Http.NOT_FOUND) {
            this.$store.commit("setDisplayNotFound", true);
          } else {
            this.$captureError(err);
            this.isLoading = false;
          }
        });
    }
  }
};
</script>
