<template>
  <div class="buttons-layout">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ButtonsLayout"
};
</script>

<style lang="scss" scoped>
.buttons-layout {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    margin-top: 0;
  }
}
</style>
