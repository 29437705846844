const moduleName = "Cuentas";

const READ_ACCOUNTS = "Ver listado de cuentas";
const READ_ACCOUNT_DETAIL = "Ver detalle de cuenta";
const READ_ACCOUNT_SERVICES = "Ver servicios de la cuenta";
const EDIT_ACCOUNT_BASIC_INFO = "Editar información básica de la cuenta";
const READ_ACCOUNT_USERS = "Ver listado de usuarios";
const LINK_USER_TO_ACCOUNT = "Vincular usuario a la cuenta";
const EDIT_ACCOUNT_BILLING_DATA =
  "Editar información de facturación de la cuenta";
const BLOCK_ACCOUNT = "Bloquear cuenta";

export default {
  moduleName,
  READ_ACCOUNTS,
  READ_ACCOUNT_DETAIL,
  READ_ACCOUNT_SERVICES,
  EDIT_ACCOUNT_BASIC_INFO,
  READ_ACCOUNT_USERS,
  LINK_USER_TO_ACCOUNT,
  EDIT_ACCOUNT_BILLING_DATA,
  BLOCK_ACCOUNT
};
