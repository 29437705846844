<template>
  <div class="d-flex h-100 flex-column">
    <div
      class="d-flex h-100 flex-column align-items-start justify-content-between flex-sm-row align-items-sm-center"
    >
      <div>
        <p>Contraseña</p>
        <p class="text info">
          Presiona resetear para obtener una nueva contraseña
        </p>
      </div>
      <z-button
        :disabled="isLoading"
        class="z-action"
        @click="setUserPasswordToDefault"
      >
        Resetear contraseña
      </z-button>
    </div>
    <quick-message
      class="mt-2"
      with-icon
      :show.sync="hasMessage"
      :type="hasErrors ? 'error' : 'success'"
      :message="message"
    />
  </div>
</template>

<script>
export default {
  name: "CardDetailPassword",
  props: {
    component: {
      type: String,
      required: true,
      validator: value => {
        return ["user", "admin", "drivers"].indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      isLoading: false,
      hasErrors: false,
      isSuccess: false,
      message: ""
    };
  },
  computed: {
    hasMessage: {
      get() {
        return this.hasErrors || this.isSuccess;
      },
      set(newVal) {
        this.hasErrors = newVal;
        this.isSuccess = newVal;
      }
    }
  },
  methods: {
    setUserPasswordToDefault() {
      this.isLoading = true;
      this.$store
        .dispatch(
          `${this.component}/setUserPasswordToDefault`,
          this.$route.params.id
        )
        .then(() => {
          const year = new Date().getFullYear();
          this.message = `Contraseña actualizada a zubut${year}`;
          this.isSuccess = true;
          setTimeout(() => {
            this.isSuccess = false;
          }, 3000);
        })
        .catch(err => {
          this.message = `Error al cambiar contraseña. HTTP ${err.statusCode}`;
          this.hasErrors = true;
          setTimeout(() => {
            this.hasErrors = false;
          }, 3000);
          this.$captureError(err);
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
p:first-child {
  font-size: 14px;
  font-weight: 600;
}

.z-action {
  min-height: 40px;
  font-weight: 500;
}

.text {
  font-size: 12px;

  &.info {
    color: $dim-gray;
    margin-top: auto;
  }
}
</style>
